import React, {useState, useEffect} from 'react'
import styles from './styles.module.sass'


const HandPickedGallery = (props) => {

  const { myHandPicked } = props;
  const [ handPicked ] = useState(myHandPicked)
  const [ currentProperty, setCurrentProperty ] = useState()

  const selectProperty = (property) => {
    setCurrentProperty(property)
  }


  useEffect(()=>{
    if(handPicked.length >= 1){
      selectProperty(handPicked[0])
    }
  }, [handPicked])

  
  return (

    <section className={'gs ' + styles['hand-picked-gallery']}>
        
        <div className={'lay-70 ' + styles['main-card']}>

          <figure className={styles['preview-photos']} style={{backgroundImage: "url("+currentProperty?.img_1+")"}}>
            <b className={styles['__id']}>{ currentProperty?.title }</b>
            <span className={styles['__location']}>{ currentProperty?.location }</span>
          </figure>

          <div className={styles['__info']}>
            <ul>
              <li>
                <figure>
                  <img src="https://lahaus.imgix.net/static/plus/investors/assets/area-outline.svg" alt=""/>
                </figure>
                <span>{currentProperty?.size_from}m² - {currentProperty?.size_to}m²</span>
              </li>
              <li>
                <figure>
                  <img src="https://lahaus.imgix.net/static/plus/investors/assets/bedroom.png" alt=""/>
                </figure>
                <span>{currentProperty?.rooms_from} - {currentProperty?.rooms_to} recámaras</span>
              </li>


              <li>
                <figure>
                  <img src="https://lahaus.imgix.net/static/plus/investors/assets/bathroom.png" alt=""/>
                </figure>
                <span>{currentProperty?.bathrooms_from} - {currentProperty?.bathrooms_to} baño</span>
              </li>

              <li>
                <figure>
                  <img src="https://lahaus.imgix.net/static/plus/investors/assets/front-car.svg" alt=""/>
                </figure>
                <span>{currentProperty?.parking_lots_to} Parking</span>
              </li>              
            </ul>
          </div>

          <div className={'gs align-items-center ' +styles['__price-n-options']}>
            <div className='lay p-20'>
              <span>Desde</span>
              <b>${ currentProperty?.price_in_millions_from } Millones { currentProperty?.currency }</b>
            </div>
            <div className='lay'>
              <a className='btn btn-block btn-sm' href={ currentProperty?.url } target="_blank" rel="noreferrer">Ver desarrollo</a>
            </div>
          </div>

        </div>

        <div className={'lay-30 ' +styles['thumbsnail-cards']}>
          {
            myHandPicked.map( (item, k) => (
              <section className={styles['thumbsnail-cards__item'] +' '+  ((currentProperty?.property_id === item?.property_id) ? styles['__active'] : null)}>
                <div className={styles['__counter']}>{k+1}</div>
                <figure className={styles['preview-photos']} key={k} style={{backgroundImage: "url("+item?.img_1+")"}} onClick={()=>(selectProperty(item))}>
                  <span className={styles['__id']}>{ item?.title }</span>
                  <span className={styles['__location']}>{ item?.location }</span>
                </figure>
              </section>        
            ))
          }
        </div>
        
    </section>
  )
}

export default HandPickedGallery


